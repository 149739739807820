
import { defineComponent, onMounted, ref, onUnmounted } from "vue";

export default defineComponent({
  name: "BottomMenu",
  props: {
    unreadCount: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  setup() {
    const bgRef = ref<SVGSVGElement>();
    const height = ref<number>(0);

    const setHeight = () => {
      if (bgRef.value) {
        const style = getComputedStyle(bgRef.value);
        height.value = parseInt(style.height.replace("px", ""));
      }
    };

    onMounted(() => {
      setHeight();

      window.addEventListener("resize", () => setHeight());
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => setHeight());
    });

    return {
      bgRef,
      height,
    };
  },
});
