
import { defineComponent } from "vue";
import CopySection from "@/components/Ui/CopySection.vue";
import ProgressBar from "@/components/Ui/ProgressBar.vue";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "PaymentInfo",
  components: {
    CopySection,
    ProgressBar,
  },
  props: {
    tether: {
      type: [String, Number],
      required: true,
    },
    qrcode: {
      required: true,
      type: String,
    },
    address: {
      required: true,
      type: String,
    },
    processLabel: {
      required: true,
      type: String,
    },
    processPercent: {
      required: true,
      type: Number,
    },
  },
  setup() {
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
