import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d287a7f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row position-relative" }
const _hoisted_2 = {
  class: "col-12 col-lg-8 desktop-transactions-list",
  ref: "desktopTransactionsList",
  dir: "ltr"
}
const _hoisted_3 = { class: "col-12 col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_desktop_transaction_card = _resolveComponent("desktop-transaction-card")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_desktop_selected_transaction = _resolveComponent("desktop-selected-transaction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (transaction, index) => {
        return (_openBlock(), _createBlock(_component_desktop_transaction_card, {
          key: index,
          type: transaction.type,
          transaction: transaction,
          selected: transaction.trackid === _ctx.selected.trackid,
          onClick: ($event: any) => (_ctx.selected = transaction)
        }, null, 8, ["type", "transaction", "selected", "onClick"]))
      }), 128)),
      _withDirectives(_createElementVNode("div", null, [
        (_ctx.right)
          ? (_openBlock(), _createBlock(_component_spinner, {
              key: 0,
              style: _normalizeStyle({ right: `${_ctx.right}px` }),
              size: "40",
              class: "desktop-transactions-list__loading"
            }, null, 8, ["style"]))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, _ctx.loading]
      ])
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_desktop_selected_transaction, { selected: _ctx.selected }, null, 8, ["selected"])
    ])
  ]))
}