
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import DesktopTransactionCard from "./DesktopTransactionCard.vue";
import DesktopSelectedTransaction from "./DesktopSelectedTransaction.vue";
import Spinner from "@/components/Ui/Spinner.vue";
import { Transaction } from "@/types/transaction";

export default defineComponent({
  name: "DesktopTransactionsList",
  components: {
    DesktopTransactionCard,
    DesktopSelectedTransaction,
    Spinner,
  },
  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true,
    },
    loading: Boolean,
  },
  emits: ["next"],
  setup(props, { emit }) {
    const selected = ref<Transaction>();
    const width = ref<number>(0);
    const desktopTransactionsList = ref<HTMLElement>();

    watch(
      () => props.transactions,
      (value) => {
        if (value.length) {
          selected.value = value[0];
        }
      },
      {
        immediate: true,
      }
    );

    const getWidth = (): number => {
      const width = desktopTransactionsList.value as HTMLElement;
      return width.offsetWidth;
    };

    onMounted(() => {
      width.value = getWidth();
    });

    onMounted(() => {
      const container = document.querySelector(
        ".desktop-transactions-list"
      ) as HTMLElement;

      container.onscroll = async () => {
        const scrollY = container.scrollHeight - container.scrollTop;
        const height = container.offsetHeight;
        const offset = height - scrollY;

        if (offset == 0 || offset == 1) {
          emit("next");
        }
      };
    });

    return {
      selected,
      desktopTransactionsList,
      right: computed(() => (width.value - 40) / 2),
    };
  },
});
