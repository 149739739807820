
import { defineComponent, PropType } from "vue";

type Item = {
  title: string;
  value: string;
  titleStyle?: string;
  valueStyle?: string;
};

export default defineComponent({
  name: "PreOrderCard",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },
});
