
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  defineAsyncComponent,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import PreOrderCard from "@/components/Shared/PreOrderCard.vue";
import AccountSelector from "@/components/Shared/AccountSelector.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Alert from "@/components/Ui/Alert.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBuyOrder from "@/composables/useBuyOrder";
import useBreakpoints from "@/composables/useBreakpoints";
import { AccountItem, PayOrderForm } from "@/types/order";

export default defineComponent({
  name: "BuyStatusPage",
  components: {
    PreOrderCard,
    Windows10Loading,
    AccountSelector,
    FormButton,
    Alert,
    PreOrderPriceBox: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderPriceBox.vue")
    ),
    PreOrderSteps: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderSteps.vue")
    ),
  },
  setup() {
    useMetaTitle("سفارش خرید");
    const route = useRoute();
    const router = useRouter();
    let timer: number;
    const payload = reactive<PayOrderForm>({
      trackid: "",
      account_id: 0,
      success_callback: "",
      fail_callback: "",
    });
    const errorMessage = ref<string>();
    const breakpoints = useBreakpoints();

    const {
      result,
      buyInvoice,
      buyInvoiceStatus: status,
      buyInvoiceError: error,
      payOrder,
      payOrderStatus,
      payOrderResponse,
    } = useBuyOrder();

    const fetchBuyInvoice = async () => {
      await buyInvoice({
        trackid: route.params.id as string,
      });
    };

    if (!result.value) {
      fetchBuyInvoice().then(() => {
        if (result.value?.status === "sending") {
          router.push({
            name: "BuyInvoice",
            params: {
              id: result.value?.public_id,
            },
          });
        }
      });
    }

    timer = setInterval(() => {
      fetchBuyInvoice();
    }, 30000);

    onUnmounted(() => {
      clearInterval(timer);
    });

    const onAccountSubmit = (account: AccountItem) => {
      payload.account_id = account.id;
      payload.trackid = route.params.id as string;
      payload.success_callback = `${window.location.origin}/account/order/buy/invoice/${result.value?.public_id}`;
      payload.fail_callback = `${window.location.origin}/account/order/buy/status/${route.params.id}?failed=true`;
    };

    const onSubmit = async () => {
      if (payOrderStatus.value !== "loading") await payOrder(payload);

      if (payOrderStatus.value === "success" && payOrderResponse.value) {
        window.location.replace(payOrderResponse.value.go2bank_url);
      }
    };

    if (route.query.failed) {
      errorMessage.value = "پرداخت ناموفق";
    }

    return {
      status,
      error,
      result,
      onAccountSubmit,
      onSubmit,
      errorMessage,
      payOrderStatus,
      payload,
      breakpoints,
    };
  },
});
