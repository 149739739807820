
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import Spinner from "@/components/Ui/Spinner.vue";
import Alert from "@/components/Ui/Alert.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBuyOrder from "@/composables/useBuyOrder";
import useBreakpoints from "@/composables/useBreakpoints";
import { copyToClipboard } from "@/utils";

export default defineComponent({
  name: "BuyInvoicePage",
  components: {
    Windows10Loading,
    MobileInvoiceSuccess: defineAsyncComponent(
      () => import("@/components/Shared/MobileInvoiceSuccess.vue")
    ),
    DesktopInvoiceSuccess: defineAsyncComponent(
      () => import("@/components/Shared/DesktopInvoiceSuccess.vue")
    ),
    PreOrderPriceBox: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderPriceBox.vue")
    ),
    PreOrderSteps: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderSteps.vue")
    ),
    Spinner,
    Alert,
  },
  setup() {
    useMetaTitle("فاکتور خرید");
    const route = useRoute();
    const router = useRouter();
    const {
      invoice,
      invoiceResponse: response,
      invoiceError: error,
      invoiceStatus: status,
    } = useBuyOrder();
    const copied = ref<boolean>(false);
    let timer: number;
    const breakpoints = useBreakpoints();

    const fetchInvoice = async () => {
      await invoice({ public_id: route.params.id as string });
    };

    fetchInvoice();

    watch(
      () => error.value,
      (value) => {
        if (value) {
          router.push({ name: "NotFound" });
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      () => response.value,
      (value) => {
        if (
          value &&
          value.result.status !== "completed" &&
          value.result.status !== "sending"
        ) {
          router.push({ name: "Order" });
        }
      }
    );

    timer = setInterval(() => {
      if (!response.value?.result.txid) {
        fetchInvoice();
      }
    }, 30000);

    onUnmounted(() => {
      clearInterval(timer);
    });

    const copy = (txt: string) => {
      copyToClipboard(txt);

      copied.value = true;
    };

    return {
      response: computed(() => response.value?.result),
      status,
      copy,
      copied,
      breakpoints,
    };
  },
});
