
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useForm, useField } from "vee-validate";
import { object, string } from "yup";
import FormInput from "@/components/Ui/FormInput.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Alert from "@/components/Ui/Alert.vue";
import { TETHER_ADDRESS_REGEX } from "@/regex";
import useBuyOrder from "@/composables/useBuyOrder";
import useBreakpoints from "@/composables/useBreakpoints";
import { ErrorType } from "@/types/base";

const validationSchema = object({
  address: string()
    .matches(TETHER_ADDRESS_REGEX, { message: "حساب تتر مقصد معتبر نیست" })
    .required("آدرس حساب را وارد کنید"),
});

export default defineComponent({
  name: "BuyForm",
  components: {
    FormInput,
    FormButton,
    Alert,
  },
  emits: ["submit"],
  setup(_, { emit }) {
    const route = useRoute();
    const { errors, meta, handleSubmit } = useForm({
      validationSchema,
    });
    const { value: address } = useField("address");
    const errorMessage = ref<string>();
    const {
      setAddressForBuy,
      setAddressForBuyStatus: status,
      setAddressForBuyError: error,
    } = useBuyOrder();
    const breakpoints = useBreakpoints();

    const onSubmit = handleSubmit(async (values) => {
      await setAddressForBuy({
        trackid: route.params.id as string,
        address: values.address as string,
      });

      if (status.value === "success") {
        emit("submit");
      }

      if (status.value === "error") {
        const { errors } = error.value as unknown as ErrorType;
        errorMessage.value = errors.address[0];
      }
    });

    return {
      address,
      errors,
      meta,
      onSubmit,
      errorMessage,
      status,
      breakpoints,
    };
  },
});
