
import { defineComponent, onMounted } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import Accounts from "@/components/Page/Account/Index/Accounts.vue";
import TetherAccount from "@/components/Page/Account/Index/TetherAccount.vue";
import VideoHelpLink from "@/components/Ui/VideoHelpLink.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useConfig from "@/composables/useConfig";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "AccountPage",
  components: {
    TetherAccount,
    VideoHelpLink,
    Accounts,
  },
  setup() {
    useMetaTitle("مدیریت حساب ها");
    const { auto_settle_video } = useConfig();
    const breakpoints = useBreakpoints();

    let body: HTMLElement;
    let title: HTMLElement;

    onMounted(() => {
      if (breakpoints.isMobile) {
        (
          document.querySelector(".account-layout__content") as HTMLElement
        ).scroll({ top: 0 });

        body = document.querySelector(".account-layout__body") as HTMLElement;
        body.style.marginTop = "-50px";

        title = document.querySelector(".top-header__title") as HTMLElement;
        title.style.marginTop = "-25px";
      }
    });

    onBeforeRouteLeave(() => {
      if (breakpoints.isMobile) {
        body.style.removeProperty("margin-top");
        title.style.removeProperty("margin-top");
      }
    });

    return {
      auto_settle_video,
      breakpoints,
    };
  },
});
