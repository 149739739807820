
import { defineComponent, ref } from "vue";
import useAccount from "@/composables/useAccount";
import NewCard from "./NewCard.vue";
import BankCard from "./BankCard.vue";
import CardsCarouselLoading from "./CardsCarouselLoading.vue";
import Modal from "@/components/Ui/Modal.vue";

export default defineComponent({
  name: "CardsList",
  components: {
    NewCard,
    BankCard,
    CardsCarouselLoading,
    Modal,
  },
  props: {
    loading: Boolean,
  },
  emits: ["new"],
  setup() {
    const modal = ref<boolean>(false);
    const selectedAccount = ref<number>(0);
    const { items, setDefault, remove } = useAccount();

    return { items, setDefault, remove, modal, selectedAccount };
  },
});
