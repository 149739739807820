
import { defineComponent, computed } from "vue";
import MobileTransactionsList from "@/components/Page/Account/Transactions/MobileTransactionsList.vue";
import DesktopTransactionsList from "@/components/Page/Account/Transactions/DesktopTransactionsList.vue";
import TransactionsEmpty from "@/components/Page/Account/Transactions/TransactionsEmpty.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useTransaction from "@/composables/useTransaction";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "TransactionsPage",
  components: {
    MobileTransactionsList,
    DesktopTransactionsList,
    TransactionsEmpty,
  },
  setup() {
    useMetaTitle("تاریخچه تراکنش ها");

    const { items, nextPage, status, fetch } = useTransaction();
    const breakpoints = useBreakpoints();

    fetch();

    return {
      items,
      nextPage,
      loading: computed(() => status.value === "loading"),
      breakpoints,
    };
  },
});
