import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  dir: "rtl",
  class: "text-end text-secondary mb-4 fsz-14"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tether_account = _resolveComponent("tether-account")!
  const _component_video_help_link = _resolveComponent("video-help-link")!
  const _component_accounts = _resolveComponent("accounts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['col-12 col-lg-4', _ctx.breakpoints.isMobile && 'order-last']),
      style: _normalizeStyle([_ctx.breakpoints.isDesktop && { paddingLeft: '50px' }])
    }, [
      (_ctx.breakpoints.isDesktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " حساب تتر TRC20 شما مخصوص تسویه خودکار: "))
        : _createCommentVNode("", true),
      _createVNode(_component_tether_account),
      _createVNode(_component_video_help_link, {
        style: {"margin-bottom":"56px"},
        title: "آموزش ویدئویی تسویه خودکار",
        url: _ctx.auto_settle_video
      }, null, 8, ["url"])
    ], 6),
    _createElementVNode("div", {
      class: _normalizeClass([
        'col-12 col-lg-8',
        _ctx.breakpoints.isMobile ? 'order-first' : 'd-flex flex-column',
      ])
    }, [
      _createVNode(_component_accounts)
    ], 2)
  ]))
}