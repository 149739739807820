
import { computed, defineComponent, getCurrentInstance, ref, watch } from "vue";
import useConfig from "@/composables/useConfig";

export default defineComponent({
  name: "FormCardNumberInput",
  props: {
    modelValue: {
      required: false,
      type: String,
      default: null,
    },
    label: {
      required: false,
      type: String,
      default: "شماره کارت",
    },
    autofocus: Boolean,
    disabled: Boolean,
    error: Boolean,
    errorMessage: {
      required: false,
      type: String,
      default: null,
    },
    hint: {
      required: false,
      type: String,
      default: null,
    },
    bgColor: {
      required: false,
      type: String,
      default: "#fff",
    },
    borderColor: {
      required: false,
      type: String,
      default: "#e0e0e0",
    },
    color: {
      required: false,
      type: String,
      default: "#afb7bd",
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const active = ref<boolean>(props.disabled ? false : props.autofocus);
    const text = ref<string>();
    const { bank_logo } = useConfig();

    watch(
      () => props.modelValue,
      (value) => {
        if (value) {
          text.value = value.replace(/-/gi, "");
        } else {
          text.value = "";
        }
      },
      { immediate: true }
    );

    watch(
      () => text.value,
      (value) => {
        const realNumber = value ? value.replace(/-/gi, "") : "";
        const dashedNumber = realNumber.match(/.{1,4}/g);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text.value = dashedNumber ? dashedNumber.join("-") : "";

        emit("update:modelValue", realNumber);
      }
    );

    const onKeydown = (e: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const key = e.key;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value: string = e.target.value;

      const keys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "۰",
        "۱",
        "۲",
        "۳",
        "۴",
        "۵",
        "۶",
        "۷",
        "۸",
        "۹",
        "Backspace",
        "ArrowRight",
        "ArrowLeft",
      ];

      if (value.replace(/-/gi, "").length >= 16 && key !== "Backspace") {
        e.preventDefault();
      }

      !keys.includes(key) && e.preventDefault();
    };

    const bankLogo = computed(() => {
      if (props.modelValue && props.modelValue.length >= 6) {
        const num: number = parseInt(props.modelValue.slice(0, 6)) as number;
        return bank_logo.value[num];
      }
      return null;
    });

    return {
      text,
      active,
      id: `input-${instance?.uid}`,
      onKeydown,
      bankLogo,
    };
  },
});
