
import { defineComponent, defineAsyncComponent } from "vue";
import TetherCalculator from "@/components/Shared/TetherCalculator/TetherCalculator.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "OrderPage",
  components: {
    TetherCalculator,
    VideoHelpLink: defineAsyncComponent(
      () => import("@/components/Ui/VideoHelpLink.vue")
    ),
    TetherChart: defineAsyncComponent(
      () => import("@/components/Shared/TetherChart.vue")
    ),
  },
  setup() {
    useMetaTitle("سفارش خرید و فروش");
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
