
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 50,
    },
  },
});
