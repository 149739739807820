import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40e0a2a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-transaction-list" }
const _hoisted_2 = {
  key: 0,
  class: "mobile-transaction-list__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_transaction_card = _resolveComponent("mobile-transaction-card")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (transaction, index) => {
      return (_openBlock(), _createBlock(_component_mobile_transaction_card, {
        key: index,
        type: transaction.type,
        transaction: transaction
      }, null, 8, ["type", "transaction"]))
    }), 128)),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_spinner, { size: "40" })
        ]))
      : _createCommentVNode("", true)
  ]))
}