
import { defineComponent, onMounted, PropType } from "vue";
import MobileTransactionCard from "./MobileTransactionCard.vue";
import Spinner from "@/components/Ui/Spinner.vue";
import { Transaction } from "@/types/transaction";

export default defineComponent({
  name: "MobileTransactionsList",
  components: {
    MobileTransactionCard,
    Spinner,
  },
  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true,
    },
    loading: Boolean,
  },
  emits: ["next"],
  setup(_, { emit }) {
    onMounted(() => {
      const container = document.querySelector(
        ".account-layout__content"
      ) as HTMLElement;

      container.onscroll = async () => {
        const scrollY = container.scrollHeight - container.scrollTop;
        const height = container.offsetHeight;
        const offset = height - scrollY;

        if (offset == 0 || offset == 1) {
          emit("next");
        }
      };
    });
  },
});
