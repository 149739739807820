
import { defineComponent, defineAsyncComponent } from "vue";
import useBreakpoints from "@/composables/useBreakpoints";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "ChatMessage",
  components: {
    ChatMessageFile: defineAsyncComponent(
      () => import("./ChatMessageFile.vue")
    ),
  },
  props: {
    answer: Boolean,
    file: Boolean,
    avatar: {
      required: true,
      type: String,
    },
    date: {
      required: false,
      type: String,
      default: "",
    },
    message: {
      required: false,
      type: String,
      default: "",
    },
    downloadLink: {
      required: false,
      type: String,
      default: "",
    },
    fileName: {
      required: false,
      type: String,
      default: "",
    },
  },
  setup() {
    const breakpoints = useBreakpoints();
    const { user } = useAuth();

    return {
      breakpoints,
      user,
    };
  },
});
