
import { defineComponent, computed, ref, watch } from "vue";
import ProfileLoading from "./ProfileLoading.vue";
import Alert from "@/components/Ui/Alert.vue";
import Unlimited from "@/components/Shared/Unlimited.vue";
import Logout from "@/components/Shared/Logout.vue";
import useAuth from "@/composables/useAuth";
import useApi, { Status } from "@/composables/useApi";
import { UploadAvatarResponse } from "@/types/auth";
import { ErrorType } from "@/types/base";

export default defineComponent({
  name: "ProfileCard",
  components: {
    ProfileLoading,
    Alert,
    Unlimited,
    Logout,
  },
  setup() {
    const inputRef = ref<HTMLInputElement>();
    const { user } = useAuth();
    const avatar = ref<string>();
    const error = ref<string>();

    watch(
      () => user.value,
      (value) => {
        avatar.value = value?.item.avatar;
      },
      {
        immediate: true,
      }
    );

    const uploadFile = async (file: File) => {
      const { call, status, response } = useApi<UploadAvatarResponse>();

      const formData = new FormData();
      formData.append("Avatar[file]", file);

      await call({
        url: "/user/upload-avatar",
        method: "POST",
        data: formData,
      });

      if (status.value === Status.SUCCESS) {
        avatar.value = response.value?.result;
      }
      if (status.value === Status.ERROR) {
        const { errors } = error.value as unknown as ErrorType;
        error.value = errors.file[0];
      }
    };

    const onFileInputChange = async (e: Event) => {
      const input = e.target as HTMLInputElement;

      if (input.files?.length) {
        const file = input.files[0];

        if (
          !["image/jpeg", "image/png", "application/pdf"].includes(file.type)
        ) {
          error.value = "آپلود فایل با فرمت jpg, jpeg, png, pdf امکان پذیر است";
        } else if (file.size > 2 * 1024 * 1024) {
          error.value = "آپلود فایل با حجم بیشتر از 2 مگابایت امکان پذیر نیست";
        } else {
          await uploadFile(file);
        }
      }
    };

    const formatedMobile = computed(() => {
      const mobile = user.value?.item.mobile;

      return mobile
        ? `(${mobile.slice(0, 4)}) ${mobile.slice(4, mobile.length)}`
        : "";
    });

    return {
      user,
      formatedMobile,
      inputRef,
      onFileInputChange,
      avatar,
      error,
    };
  },
});
