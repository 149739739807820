import { reactive, toRefs, computed } from "vue";
import useApi, { Status } from "./useApi";
import {
  AccountListResponse,
  SetAccountDefaultResponse,
} from "@/types/account";

const state = reactive<AccountListResponse>({
  items: [],
});

export default () => {
  const { call, status, response } = useApi<AccountListResponse>();
  const { call: setDefaultCall } = useApi<SetAccountDefaultResponse>();

  const fetch = async () => {
    await call({
      url: "/user/account-list",
      method: "GET",
    });

    if (status.value === Status.SUCCESS && response.value) {
      state.items = response.value.items;
    }
  };

  const setDefault = async (id: number) => {
    await setDefaultCall({
      url: "/user/set-default-account",
      method: "POST",
      data: {
        id,
      },
    });

    await fetch();
  };

  const remove = async (id: number) => {
    await call({
      url: "/user/remove-account",
      method: "POST",
      data: {
        id,
      },
    });

    await fetch();
  };

  return {
    ...toRefs(state),
    fetch,
    loading: computed(() => status.value === Status.LOADING),
    setDefault,
    remove,
  };
};
