
import { defineComponent } from "vue";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "NewCard",
  emits: ["click"],
  setup() {
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
