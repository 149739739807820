
import { defineComponent, PropType, ref, computed } from "vue";
import Alert from "@/components/Ui/Alert.vue";
import { Transaction } from "@/types/transaction";
import { copyToClipboard } from "@/utils";

export default defineComponent({
  name: "DesktopSelectedTransaction",
  components: {
    Alert,
  },
  props: {
    selected: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    const copied = ref<boolean>(false);

    const copy = (txt: string) => {
      copyToClipboard(txt);
      copied.value = true;
    };

    const status_list = {
      waiting: "منتظر",
      processing: "در حال انجام",
      completed: "تکمیل شده",
      expired: "منقضی شده",
      rejected: "برگشت داده شده",
      sending: "در حال انجام",
    };

    const status = computed(() => {
      return status_list[props.selected.status];
    });

    return {
      copied,
      copy,
      status,
    };
  },
});
