import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-558c7378"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_message_file = _resolveComponent("chat-message-file")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['chat-message', _ctx.answer && 'chat-message--answer'])
  }, [
    (_ctx.breakpoints.isDesktop && !_ctx.answer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.avatar,
            alt: "avatar",
            class: _normalizeClass([
          'chat-message__avatar',
          _ctx.answer && 'chat-message__avatar--answer',
        ])
          }, null, 10, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.breakpoints.isDesktop)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([
          'chat-message__desktop-top',
          _ctx.answer && 'chat-message__desktop-top--answer',
        ])
          }, [
            (_ctx.user && !_ctx.answer)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`${_ctx.user.item.name} ${_ctx.user.item.lastname} | `) + "   ", 1))
              : _createCommentVNode("", true),
            (_ctx.answer)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "پشتیبانی |  "))
              : _createCommentVNode("", true),
            (_ctx.date)
              ? (_openBlock(), _createElementBlock("time", {
                  key: 2,
                  class: _normalizeClass([
            'chat-message__date chat-message__date--desktop',
            _ctx.answer && 'chat-message__date--answer',
          ])
                }, _toDisplayString(` ${_ctx.date.split(" ")[0]} ${_ctx.date.split(" ")[1]} ${
              _ctx.date.split(" ")[2] || ""
            }`), 3))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([
          'chat-message__item',
          _ctx.answer && 'chat-message__item--answer',
          _ctx.breakpoints.isDesktop && 'chat-message__item--desktop',
          _ctx.breakpoints.isDesktop &&
            _ctx.answer &&
            'chat-message__item--desktop-answer',
        ])
      }, [
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "chat-message__text",
              style: _normalizeStyle({ marginBottom: `${_ctx.file ? 19 : 0}px` })
            }, _toDisplayString(_ctx.message), 5))
          : _createCommentVNode("", true),
        (_ctx.file)
          ? (_openBlock(), _createBlock(_component_chat_message_file, {
              key: 1,
              answer: _ctx.answer,
              "download-link": _ctx.downloadLink,
              "file-name": _ctx.fileName
            }, null, 8, ["answer", "download-link", "file-name"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.date && _ctx.breakpoints.isMobile)
        ? (_openBlock(), _createElementBlock("time", {
            key: 1,
            class: _normalizeClass(['chat-message__date', _ctx.answer && 'chat-message__date--answer'])
          }, _toDisplayString(_ctx.date), 3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.breakpoints.isDesktop && _ctx.answer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.avatar,
            alt: "avatar",
            class: _normalizeClass([
          'chat-message__avatar',
          _ctx.answer && 'chat-message__avatar--answer',
        ])
          }, null, 10, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}