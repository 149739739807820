import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4598e706"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tether-account__title"
}
const _hoisted_2 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_section = _resolveComponent("copy-section")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'tether-account',
      _ctx.breakpoints.isDesktop && 'tether-account--desktop',
    ])
  }, [
    (_ctx.breakpoints.isMobile)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " حساب تتر TRC20 شما مخصوص تسویه خودکار: "))
      : _createCommentVNode("", true),
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
        'tether-account__content',
        _ctx.breakpoints.isDesktop && 'tether-account__content--desktop',
      ])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
          'tether-account__inner',
          _ctx.breakpoints.isDesktop && 'tether-account__inner--desktop',
        ])
          }, [
            _createElementVNode("img", {
              src: _ctx.user.qr_main_address,
              alt: "qrcode",
              width: _ctx.breakpoints.isDesktop ? '166' : '125',
              height: _ctx.breakpoints.isDesktop ? '166' : '125'
            }, null, 8, _hoisted_2)
          ], 2),
          _createVNode(_component_copy_section, {
            text: _ctx.user.main_address
          }, null, 8, ["text"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}