
import { defineComponent } from "vue";
import CopySection from "@/components/Ui/CopySection.vue";
import useAuth from "@/composables/useAuth";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "TetherAccount",
  components: {
    CopySection,
  },
  setup() {
    const { user } = useAuth();
    const breakpoints = useBreakpoints();

    return {
      user,
      breakpoints,
    };
  },
});
