
import { defineComponent, ref } from "vue";
import Alert from "@/components/Ui/Alert.vue";
import { copyToClipboard } from "@/utils";

export default defineComponent({
  name: "CopySection",
  components: {
    Alert,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const copied = ref<boolean>(false);

    const copy = () => {
      copyToClipboard(props.text);

      copied.value = true;
    };

    return {
      copied,
      copy,
    };
  },
});
