import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row"
}
const _hoisted_2 = { class: "col-12 col-lg-4" }
const _hoisted_3 = { class: "col-12 col-lg-8 d-flex flex-column justify-content-between position-relative" }
const _hoisted_4 = {
  key: 1,
  class: "order-heading pb-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_windows_10_loading = _resolveComponent("windows-10-loading")!
  const _component_tether_calculator = _resolveComponent("tether-calculator")!
  const _component_pre_order_card = _resolveComponent("pre-order-card")!
  const _component_account_selector = _resolveComponent("account-selector")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.sellInvoiceStatus === 'loading' && _ctx.breakpoints.isMobile)
      ? (_openBlock(), _createBlock(_component_windows_10_loading, {
          key: 0,
          label: "در حال پردازش ..."
        }))
      : _createCommentVNode("", true),
    (_ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createBlock(_component_tether_calculator, {
                  key: 0,
                  "active-tab": "sell",
                  "default-pay": _ctx.$route.query?.pay,
                  "default-recieve": _ctx.$route.query?.receive,
                  flat: ""
                }, null, 8, ["default-pay", "default-recieve"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.sellInvoiceStatus === 'loading' && _ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createBlock(_component_windows_10_loading, {
                  key: 0,
                  class: "position-absolute",
                  label: "در حال پردازش ..."
                }))
              : _createCommentVNode("", true),
            (_ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " سفارش فروش تتر "))
              : _createCommentVNode("", true),
            _createVNode(_component_pre_order_card, {
              title: _ctx.breakpoints.isMobile ? 'سفارش فروش تتر' : '',
              items: [
          {
            title: 'مقدار',
            value: `${new Intl.NumberFormat().format(_ctx.result.amount)} USDT`,
            titleStyle: _ctx.breakpoints.isDesktop && 'font-size: 18px',
            valueStyle: `font-family: IRANSansX; direction: ltr; ${
              _ctx.breakpoints.isDesktop && 'font-size: 18px; font-weight: 500;'
            }`,
          },
          {
            title: 'نرخ',
            value: `${parseInt(_ctx.result.rate).toLocaleString()} تومان`,
            titleStyle: _ctx.breakpoints.isDesktop && 'font-size: 18px',
            valueStyle: `font-weight: 500; ${
              _ctx.breakpoints.isDesktop && 'font-size: 18px'
            }`,
          },
          {
            title: 'کارمزد حواله بانکی',
            value: `${_ctx.result.settle_fee.toLocaleString()} تومان`,
            titleStyle: _ctx.breakpoints.isDesktop && 'font-size: 18px',
            valueStyle: `font-weight: 500; ${
              _ctx.breakpoints.isDesktop && 'font-size: 18px'
            }`,
          },
          {
            title: 'مبلغ نهایی دریافتی',
            value: `${parseInt(_ctx.result.final).toLocaleString()} تومان`,
            titleStyle: `font-weight: bold; white-space: nowrap; ${
              _ctx.breakpoints.isDesktop && 'font-size: 18px'
            }`,
            valueStyle: `font-weight: bold; white-space: nowrap; ${
              _ctx.breakpoints.isDesktop && 'font-size: 18px'
            }`,
          },
        ]
            }, null, 8, ["title", "items"]),
            _createElementVNode("div", null, [
              _createVNode(_component_account_selector, {
                type: "sheba",
                accounts: _ctx.result.account_items,
                heading: "واریز مبلغ ریالی به",
                onSubmit: _ctx.onAccountSubmit,
                "required-field": "ir_num"
              }, null, 8, ["accounts", "onSubmit"]),
              _createVNode(_component_form_button, {
                onClick: _ctx.onSubmit,
                label: "تایید و ادامه",
                disabled: !_ctx.result.account_items.length,
                borderedDisabled: 
            !_ctx.result.account_items.length && _ctx.breakpoints.isMobile
          ,
                "full-width": "",
                loading: _ctx.status === 'loading',
                type: "button",
                class: _normalizeClass([_ctx.breakpoints.isMobile && 'mb-4'])
              }, null, 8, ["onClick", "disabled", "borderedDisabled", "loading", "class"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}