
import { defineComponent } from "vue";

export default defineComponent({
  name: "TetherResult",
  props: {
    tether: {
      type: [String, Number],
      required: true,
    },
    toman: {
      type: [String, Number],
      required: true,
    },
  },
});
