import { reactive, toRefs } from "vue";
import useApi from "./useApi";
import { TransactionListResponse, Transaction } from "@/types/transaction";

const state = reactive<{ items: Transaction[] }>({
  items: [],
});

export default () => {
  const { call, response, status } = useApi<TransactionListResponse>();

  const fetch = async (page = 1) => {
    await call({
      url: "/user/transaction-list",
      method: "GET",
      params: {
        page,
      },
    });

    if (response.value) {
      if (response.value.page > 1) {
        state.items.push(...response.value.items);
      } else {
        state.items = response.value.items;
      }
    }
  };

  const nextPage = async () => {
    if (response.value && response.value.is_next_page_available) {
      await fetch(response.value.page + 1);
    }
  };

  return {
    ...toRefs(state),
    response,
    status,
    fetch,
    nextPage,
  };
};
