import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6065b7bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "payment-info__heading"
}
const _hoisted_2 = { class: "payment-info" }
const _hoisted_3 = { class: "payment-info__content" }
const _hoisted_4 = { class: "payment-info__tether" }
const _hoisted_5 = { class: "payment-info__inner" }
const _hoisted_6 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_section = _resolveComponent("copy-section")!
  const _component_progress_bar = _resolveComponent("progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.breakpoints.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " اطلاعات پرداخت: "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(" مقدار "),
        _createElementVNode("span", _hoisted_4, _toDisplayString(parseInt(_ctx.tether).toLocaleString()), 1),
        _createTextVNode(" تتر را در یک تراکنش به آدرس TRC20 زیر واریز کنید. ")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.qrcode,
          alt: "qrcode",
          width: _ctx.breakpoints.isDesktop ? 150 : 125,
          height: _ctx.breakpoints.isDesktop ? 150 : 125
        }, null, 8, _hoisted_6)
      ]),
      _createVNode(_component_copy_section, { text: _ctx.address }, null, 8, ["text"]),
      (_ctx.breakpoints.isMobile)
        ? (_openBlock(), _createBlock(_component_progress_bar, {
            key: 0,
            class: "mt-3",
            percentage: _ctx.processPercent,
            label: _ctx.processLabel
          }, null, 8, ["percentage", "label"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}