
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TopHeader",
  setup() {
    const route = useRoute();

    const height = computed(() => {
      if (route.name === "Profile") {
        return "237px";
      }
      return "";
    });

    return {
      height,
    };
  },
});
