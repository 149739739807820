
import { defineComponent, PropType } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { cardNumberRegex, isShebaValid } from "@persian-tools/persian-tools";
import FormCardNumberInput from "@/components/Ui/FormCardNumberInput.vue";
import FormInput from "@/components/Ui/FormInput.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import useApi, { Status } from "@/composables/useApi";
import { ErrorType } from "@/types/base";

const validationSchema = yup.object().shape(
  {
    card_num: yup.string().when("ir_num", {
      is: (val: string) => !val,
      then: yup
        .string()
        .required("لطفا شماره کارت بانکی را وارد کنید.")
        .matches(cardNumberRegex, {
          message: "شماره کارت بانکی باید 16 رقم باشد.",
        }),
    }),
    ir_num: yup.string().when("card_num", {
      is: (val: string) => !val,
      then: yup
        .string()
        .required("لطفا شماره شبا را وارد کنید.")
        .test("ir_num", "شماره شبا معتبر نیست.", (value) => {
          if (value) {
            return isShebaValid(value);
          }

          return true;
        }),
    }),
  },
  [["card_num", "ir_num"]]
);

export default defineComponent({
  name: "BankAccountRegistrationForm",
  components: {
    FormCardNumberInput,
    FormInput,
    FormButton,
  },
  props: {
    requiredField: {
      type: String as PropType<"card_num" | "ir_num">,
      required: false,
      validator: (value: string) =>
        value === "card_num" || value === "ir_num" || !value,
      default: "",
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const schema = () => {
      if (props.requiredField === "ir_num") {
        return yup.object({
          ir_num: yup
            .string()
            .required("لطفا شماره شبا را وارد کنید.")
            .test("ir_num", "شماره شبا معتبر نیست.", (value) => {
              return value && isShebaValid(value) ? true : false;
            }),
          card_num: yup.string().matches(cardNumberRegex, {
            message: "شماره کارت بانکی باید 16 رقم باشد.",
          }),
        });
      } else if (props.requiredField === "card_num") {
        return yup.object({
          ir_num: yup
            .string()
            .test("ir_num", "شماره شبا معتبر نیست.", (value) => {
              if (value) {
                return isShebaValid(value);
              }

              return true;
            }),
          card_num: yup
            .string()
            .required("لطفا شماره کارت بانکی را وارد کنید.")
            .matches(cardNumberRegex, {
              message: "شماره کارت بانکی باید 16 رقم باشد.",
            }),
        });
      } else {
        return validationSchema;
      }
    };

    const { handleSubmit, meta, errors, setFieldError, handleReset } = useForm({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validationSchema: schema(),
    });
    const { value: card_num } = useField("card_num");
    const { value: ir_num } = useField("ir_num");

    const { call, status, error } = useApi();

    const onSubmit = handleSubmit(async (values) => {
      const data = values;

      await call({
        url: "/user/add-account",
        method: "POST",
        data,
      });

      if (status.value === Status.ERROR) {
        const errors = error.value as unknown as ErrorType;
        Object.entries(errors.errors).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldError(key, value);
        });
      }

      if (status.value === Status.SUCCESS) {
        emit("submit", {
          card_num: card_num.value,
          ir_num: ir_num.value ? `IR${ir_num.value}` : undefined,
        });
        handleReset();
      }
    });

    return {
      meta,
      errors,
      card_num,
      ir_num,
      onSubmit,
      status,
    };
  },
});
