import { Transition as _Transition, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_message = _resolveComponent("chat-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'chat-messages-list',
      _ctx.breakpoints.isDesktop && 'chat-messages-list--desktop',
    ])
  }, [
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut",
      class: "animate__faster",
      mode: "out-in"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createBlock(_component_chat_message, {
        key: message.id,
        message: message.msg.trim() ? message.msg : '',
        answer: !message.is_user,
        date: message.jdate,
        file: message.is_file,
        "download-link": message.download_link,
        "file-name": message.file_name,
        avatar: message.avatar
      }, null, 8, ["message", "answer", "date", "file", "download-link", "file-name", "avatar"]))
    }), 128))
  ], 2))
}