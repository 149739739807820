import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_card_number_input = _resolveComponent("form-card-number-input")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createVNode(_component_form_card_number_input, {
      modelValue: _ctx.card_num,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.card_num) = $event)),
      "error-message": _ctx.errors.card_num,
      error: 
        (_ctx.meta.dirty && Boolean(_ctx.errors.card_num)) || Boolean(_ctx.errors.card_num)
      ,
      class: _normalizeClass([
        (_ctx.meta.dirty && Boolean(_ctx.errors.card_num)) || Boolean(_ctx.errors.card_num)
          ? 'mb-5'
          : 'mb-4',
      ])
    }, null, 8, ["modelValue", "error-message", "error", "class"]),
    _createVNode(_component_form_input, {
      modelValue: _ctx.ir_num,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ir_num) = $event)),
      "error-message": _ctx.errors.ir_num,
      error: (_ctx.meta.dirty && Boolean(_ctx.errors.ir_num)) || Boolean(_ctx.errors.ir_num),
      class: "mb-5",
      label: "شماره شبا",
      hint: "لطفا شماره شبا را با IR وارد کنید."
    }, null, 8, ["modelValue", "error-message", "error"]),
    _createVNode(_component_form_button, {
      loading: _ctx.status === 'loading',
      type: "submit",
      "full-width": "",
      label: "بررسی اطلاعات"
    }, null, 8, ["loading"])
  ], 32))
}