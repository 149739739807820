import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cbe0524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-input form-account-selector" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "form-account-selector__wrapper" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'form-input__inner',
        _ctx.active && 'form-input__inner--active',
        _ctx.disabled && 'form-input__inner--disabled',
        _ctx.error && 'form-input__inner--error',
      ])
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([
          'form-input__label form-input__label--active',
          _ctx.disabled && 'form-input__label--disabled',
          _ctx.error && 'form-input__label--error',
        ]),
        for: _ctx.id
      }, _toDisplayString(_ctx.label), 11, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.currentAccount)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.currentAccount.logo,
              alt: _ctx.currentAccount.bank_name,
              class: "form-account-selector__prepend-img"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("select", {
          class: _normalizeClass([
            'form-account-selector__html',
            _ctx.accounts.length <= 1 && 'form-account-selector__html--single',
            !_ctx.isIOS && 'form-account-selector__html--not-ios',
          ]),
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event.target.value))),
          onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.accounts.length <= 1 && $event.preventDefault()))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (account, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              value: _ctx.type === 'sheba' ? account.ir_num : account.card_num,
              selected: 
              _ctx.type === 'sheba'
                ? account.ir_num === _ctx.modelValue
                : account.card_num === _ctx.modelValue
            
            }, _toDisplayString(_ctx.type === "sheba"
                ? _ctx.formatedSheba(account.ir_num)
                : _ctx.formatedCard(account.card_num)), 9, _hoisted_5))
          }), 128))
        ], 34)
      ])
    ], 2)
  ]))
}