import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "row buy-status-page"
}
const _hoisted_2 = { class: "col-12 col-lg-4 d-flex flex-column" }
const _hoisted_3 = { class: "col-12 col-lg-8 d-lg-flex flex-lg-column" }
const _hoisted_4 = {
  key: 0,
  class: "order-heading"
}
const _hoisted_5 = { class: "flex-lg-grow-1 d-lg-flex flex-lg-column justify-content-lg-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_windows_10_loading = _resolveComponent("windows-10-loading")!
  const _component_pre_order_price_box = _resolveComponent("pre-order-price-box")!
  const _component_pre_order_steps = _resolveComponent("pre-order-steps")!
  const _component_pre_order_card = _resolveComponent("pre-order-card")!
  const _component_account_selector = _resolveComponent("account-selector")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_alert, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.errorMessage = undefined)),
          variant: "danger",
          title: _ctx.errorMessage,
          content: "پرداخت شما ناموفق بود . در صورتی که مبلغی از کارت شما کسر شده است بصورت اتوماتیک طی حداکثر یک ساعت توسط بانک برگشت زده خواهد شد.",
          duration: 10
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.status === 'loading' && !_ctx.result)
      ? (_openBlock(), _createBlock(_component_windows_10_loading, {
          key: 1,
          label: "در حال پردازش ،،،"
        }))
      : _createCommentVNode("", true),
    (_ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_pre_order_price_box, {
                    toman: _ctx.result.total,
                    tether: _ctx.result.amount
                  }, null, 8, ["toman", "tether"]),
                  _createVNode(_component_pre_order_steps, {
                    list: _ctx.result.state_list
                  }, null, 8, ["list"])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              (_ctx.breakpoints.isDesktop)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " سفارش خرید تتر "))
                : _createCommentVNode("", true),
              _createVNode(_component_pre_order_card, {
                items: [
            {
              title: 'مقدار',
              value: `${_ctx.result.amount.toLocaleString()} USDT`,
              titleStyle: 'font-weight: 500;',
              valueStyle: 'font-family: IRANSansX; direction: ltr;',
            },
            {
              title: 'نرخ',
              value: `${Number(_ctx.result.rate).toLocaleString()} تومان`,
            },
            {
              title: 'کارمزد شبکه',
              value: `${_ctx.result.transaction_fee.toLocaleString()} USDT`,
              valueStyle: 'font-family: IRANSansX; direction: ltr;',
            },
            {
              title: 'مقدار نهایی دریافتی',
              value: `${_ctx.result.final} USDT`,
              valueStyle: 'font-family: IRANSansX; direction: ltr;',
            },
            {
              title: 'حساب مقصد',
              value: _ctx.result.address,
              titleStyle: 'white-space: nowrap;',
              valueStyle:
                'white-space: nowrap; display: flex; align-items: center;',
            },
            {
              title: 'مبلغ نهایی پرداخت شما',
              value: `${new Intl.NumberFormat().format(_ctx.result.total)} تومان`,
              titleStyle: 'font-weight: bold;',
              valueStyle: 'font-weight: bold;',
            },
          ]
              }, null, 8, ["items"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_account_selector, {
                type: "card",
                accounts: _ctx.result.account_items,
                heading: "پرداخت از کارت",
                onSubmit: _ctx.onAccountSubmit,
                label: "کارت ها",
                "required-field": "card_num"
              }, null, 8, ["accounts", "onSubmit"]),
              _createVNode(_component_form_button, {
                onClick: _ctx.onSubmit,
                type: "button",
                "full-width": "",
                label: "تایید و پرداخت",
                class: _normalizeClass([_ctx.breakpoints.isMobile && 'mb-4']),
                loading: _ctx.payOrderStatus === 'loading',
                disabled: _ctx.payload.account_id === 0,
                borderedDisabled: _ctx.payload.account_id === 0 && _ctx.breakpoints.isMobile
              }, null, 8, ["onClick", "class", "loading", "disabled", "borderedDisabled"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}