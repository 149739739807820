import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f419f8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_chat_empty = _resolveComponent("chat-empty")!
  const _component_chat_messages_list = _resolveComponent("chat-messages-list")!
  const _component_chat_message_box = _resolveComponent("chat-message-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['chat', _ctx.breakpoints.isDesktop && 'chat--desktop']),
    style: _normalizeStyle([_ctx.breakpoints.isDesktop && { height: `${_ctx.height}px` }])
  }, [
    (_ctx.errorMessage && _ctx.breakpoints.isMobile)
      ? (_openBlock(), _createBlock(_component_alert, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.errorMessage = undefined)),
          variant: "danger",
          title: _ctx.errorMessage
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (!_ctx.messages.length)
      ? (_openBlock(), _createBlock(_component_chat_empty, { key: 1 }))
      : (_openBlock(), _createBlock(_component_chat_messages_list, {
          key: 2,
          loading: _ctx.loading,
          messages: _ctx.messages
        }, null, 8, ["loading", "messages"])),
    (_ctx.breakpoints.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_chat_message_box, {
            onSendMessage: _ctx.onSendMessage,
            onError: _ctx.onError
          }, null, 8, ["onSendMessage", "onError"])
        ]))
      : _createCommentVNode("", true)
  ], 6))
}