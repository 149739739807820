import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_bank_account_registration_form = _resolveComponent("bank-account-registration-form")!
  const _component_modal = _resolveComponent("modal")!
  const _component_cards_carousel_loading = _resolveComponent("cards-carousel-loading")!
  const _component_cards_carousel = _resolveComponent("cards-carousel")!
  const _component_cards_list = _resolveComponent("cards-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.accountRegistrationFormSuccess)
      ? (_openBlock(), _createBlock(_component_alert, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.accountRegistrationFormSuccess = false)),
          variant: "success",
          title: "با موفقیت انجام شد!"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modal) = $event))
    }, {
      header: _withCtx(() => [
        _createTextVNode("ثبت حساب بانکی جدید")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bank_account_registration_form, { onSubmit: _ctx.onSubmit }, null, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.breakpoints.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_cards_carousel_loading, { key: 0 }))
            : (_openBlock(), _createBlock(_component_cards_carousel, {
                key: 1,
                onNew: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modal = true))
              }))
        ], 64))
      : (_ctx.breakpoints.isDesktop)
        ? (_openBlock(), _createBlock(_component_cards_list, {
            key: 2,
            loading: _ctx.loading,
            onNew: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modal = true))
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true)
  ], 64))
}