import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-575c9ded"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pre-order-card" }
const _hoisted_2 = {
  key: 0,
  class: "pre-order-card__title"
}
const _hoisted_3 = { class: "pre-order-card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "pre-order-card__content-item"
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(item.titleStyle),
            class: "pre-order-card__content-item-title"
          }, _toDisplayString(item.title) + ": ", 5),
          _createElementVNode("div", {
            style: _normalizeStyle(item.valueStyle),
            class: "pre-order-card__content-item-value"
          }, _toDisplayString(item.value), 5)
        ]))
      }), 128))
    ])
  ]))
}