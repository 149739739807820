
import {
  defineComponent,
  reactive,
  onUnmounted,
  defineAsyncComponent,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import PreOrderCard from "@/components/Shared/PreOrderCard.vue";
import AccountSelector from "@/components/Shared/AccountSelector.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useSellOrder from "@/composables/useSellOrder";
import useBreakpoints from "@/composables/useBreakpoints";
import {
  AccountItem,
  OrderResponse,
  setAccountForSellOrderForm,
} from "@/types/order";

export default defineComponent({
  name: "SellPage",
  components: {
    PreOrderCard,
    AccountSelector,
    FormButton,
    Windows10Loading,
    TetherCalculator: defineAsyncComponent(
      () => import("@/components/Shared/TetherCalculator/TetherCalculator.vue")
    ),
  },
  setup() {
    useMetaTitle("سفارش فروش");
    const router = useRouter();
    const route = useRoute();
    const payload = reactive<setAccountForSellOrderForm>({
      trackid: "",
      account_id: 0,
    });
    let timer: number;
    const breakpoints = useBreakpoints();

    const {
      result,
      sellInvoice,
      sellInvoiceStatus,
      setAccountForSellOrder,
      setAccountForSellOrderStatus,
    } = useSellOrder();

    const fetchSellInvoice = async () => {
      sellInvoice({
        trackid: route.params.id as string,
      });
    };

    if (!result.value) {
      fetchSellInvoice();
    }

    timer = setInterval(() => {
      fetchSellInvoice();
    }, 30000);

    const onAccountSubmit = (account: AccountItem) => {
      payload.account_id = account.id;
      payload.trackid = (result.value as OrderResponse["result"]).trackid;
    };

    const onSubmit = async () => {
      await setAccountForSellOrder(payload);

      if (setAccountForSellOrderStatus.value === "success") {
        router.push({
          name: "SellStatus",
          params: { id: result.value?.trackid },
        });
      }
    };

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {
      result,
      onAccountSubmit,
      onSubmit,
      status: setAccountForSellOrderStatus,
      sellInvoiceStatus,
      breakpoints,
    };
  },
});
