
import { defineComponent, PropType, ref, watch } from "vue";
import { useRoute } from "vue-router";
import FormAccountSelector from "@/components/Ui/FormAccountSelector.vue";
import Modal from "@/components/Ui/Modal.vue";
import BankAccountRegistrationForm from "@/components/Shared/BankAccountRegistrationForm.vue";
import Alert from "@/components/Ui/Alert.vue";
import useSellOrder from "@/composables/useSellOrder";
import useBuyOrder from "@/composables/useBuyOrder";
import useBreakpoints from "@/composables/useBreakpoints";
import { AccountItem } from "@/types/order";

export default defineComponent({
  name: "AccountSelector",
  components: {
    FormAccountSelector,
    Modal,
    BankAccountRegistrationForm,
    Alert,
  },
  props: {
    heading: {
      required: true,
      type: String,
    },
    accounts: {
      required: true,
      type: Array as PropType<AccountItem[]>,
    },
    type: {
      required: true,
      type: String as PropType<"sheba" | "card">,
      validator: (value: string) => value === "sheba" || value === "card",
    },
    label: {
      required: false,
      type: String,
    },
    requiredField: {
      type: String as PropType<"card_num" | "ir_num">,
      required: true,
      validator: (value: string) => value === "card_num" || value === "ir_num",
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const card = ref<string>();
    const modal = ref<boolean>(false);
    const route = useRoute();
    const successAlert = ref<boolean>(false);
    const breakpoints = useBreakpoints();

    const { result: sellOrderResult, sellInvoice } = useSellOrder();
    const { result: buyOrderResult, buyInvoice } = useBuyOrder();

    if (props.accounts.length) {
      card.value = props.accounts[0][
        props.type === "card" ? "card_num" : "ir_num"
      ] as string;
    }

    // watch(
    //   () => props.accounts,
    //   (value) => {
    //     if (value.length) {
    //       const defaultAccount = value.find((account) => account.is_default);
    //       if (defaultAccount) {
    //         card.value = defaultAccount.ir_num;
    //       } else {
    //         card.value = value[0].ir_num;
    //       }
    //     }
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    const onSubmit = async ({
      ir_num,
      card_num,
    }: {
      ir_num: string;
      card_num: string;
    }) => {
      if (sellOrderResult.value && route.name === "Sell") {
        await sellInvoice({ trackid: sellOrderResult.value.trackid });

        card.value = ir_num;
        modal.value = false;
      }

      if (buyOrderResult.value && route.name === "BuyStatus") {
        await buyInvoice({ trackid: buyOrderResult.value.trackid });

        card.value = card_num;
        modal.value = false;
      }

      successAlert.value = true;
    };

    watch(
      () => card.value,
      (value) => {
        const foundAccount = props.accounts.find((account) => {
          if (props.type === "card") {
            return account.card_num === value;
          } else {
            return account.ir_num === value;
          }
        });

        if (foundAccount) {
          emit("submit", foundAccount);
        } else {
          props.accounts.length && emit("submit", props.accounts[0]);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      card,
      modal,
      onSubmit,
      successAlert,
      breakpoints,
    };
  },
});
