import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e8b601c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "autofocus", "disabled"]
const _hoisted_4 = { class: "form-input__bank-logo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "form-input__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'form-input__inner',
        _ctx.active && 'form-input__inner--active',
        _ctx.disabled && 'form-input__inner--disabled',
        _ctx.error && 'form-input__inner--error',
      ])
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([
          'form-input__label',
          (_ctx.active || _ctx.text) && 'form-input__label--active',
          _ctx.disabled && 'form-input__label--disabled',
          _ctx.error && 'form-input__label--error',
        ]),
        for: _ctx.id,
        style: _normalizeStyle([
          { backgroundColor: _ctx.bgColor },
          !_ctx.bgColor && { backgroundColor: '#fff' },
          _ctx.modelValue && _ctx.modelValue.length && !_ctx.active && { color: _ctx.color },
        ])
      }, _toDisplayString(_ctx.label), 15, _hoisted_2),
      _withDirectives(_createElementVNode("input", {
        style: _normalizeStyle({ backgroundColor: _ctx.bgColor }),
        class: "form-input__html",
        inputmode: "numeric",
        id: _ctx.id,
        type: "text",
        autofocus: _ctx.autofocus,
        disabled: _ctx.disabled,
        maxlength: "19",
        onFocusout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = false)),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = true)),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.text) = $event))
      }, null, 44, _hoisted_3), [
        [_vModelText, _ctx.text]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          width: "26",
          height: "26",
          src: _ctx.bankLogo
        }, null, 8, _hoisted_5)
      ], 512), [
        [_vShow, _ctx.bankLogo]
      ])
    ], 2),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut",
      class: "animate__faster",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.hint && !_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.hint), 1))
          : (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(['form-input__hint', _ctx.error && 'form-input__hint--error'])
              }, _toDisplayString(_ctx.errorMessage), 3))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}