import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row"
}
const _hoisted_2 = { class: "col-12 col-lg-4 d-flex flex-column" }
const _hoisted_3 = { class: "col-12 col-lg-8 position-relative d-flex flex-column justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_windows_10_loading = _resolveComponent("windows-10-loading")!
  const _component_pre_order_price_box = _resolveComponent("pre-order-price-box")!
  const _component_pre_order_steps = _resolveComponent("pre-order-steps")!
  const _component_tether_result = _resolveComponent("tether-result")!
  const _component_payment_info = _resolveComponent("payment-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.status === 'loading' && _ctx.breakpoints.isMobile)
      ? (_openBlock(), _createBlock(_component_windows_10_loading, {
          key: 0,
          label: "در حال پردازش ..."
        }))
      : _createCommentVNode("", true),
    (_ctx.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_pre_order_price_box, {
                    toman: _ctx.result.total,
                    tether: _ctx.result.amount,
                    reverse: ""
                  }, null, 8, ["toman", "tether"]),
                  _createVNode(_component_pre_order_steps, {
                    list: _ctx.result.state_list
                  }, null, 8, ["list"])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.status === 'loading' && _ctx.breakpoints.isDesktop)
              ? (_openBlock(), _createBlock(_component_windows_10_loading, {
                  key: 0,
                  class: "position-absolute",
                  label: "در حال پردازش ..."
                }))
              : _createCommentVNode("", true),
            (_ctx.breakpoints.isMobile)
              ? (_openBlock(), _createBlock(_component_tether_result, {
                  key: 1,
                  tether: _ctx.result.amount,
                  toman: _ctx.result.total
                }, null, 8, ["tether", "toman"]))
              : _createCommentVNode("", true),
            _createVNode(_component_payment_info, {
              tether: _ctx.result.amount,
              qrcode: _ctx.result.qr_address,
              address: _ctx.result.address,
              "process-label": _ctx.result.progress_title,
              "process-percent": _ctx.result.progress_percent
            }, null, 8, ["tether", "qrcode", "address", "process-label", "process-percent"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}