
import { defineComponent, PropType } from "vue";
import { Transaction } from "@/types/transaction";

export default defineComponent({
  name: "DesktopTransactionCard",
  props: {
    type: {
      required: true,
      type: String as PropType<"buy" | "sell">,
    },
    transaction: {
      required: true,
      type: Object as PropType<Transaction>,
    },
    selected: Boolean,
  },
  emits: ["click"],
});
