
import {
  defineComponent,
  onMounted,
  watch,
  computed,
  onUnmounted,
  ref,
  nextTick,
} from "vue";
import { onBeforeRouteLeave } from "vue-router";
import ChatEmpty from "./ChatEmpty.vue";
import ChatMessagesList from "./ChatMessagesList.vue";
import ChatMessageBox from "./ChatMessageBox.vue";
import Alert from "@/components/Ui/Alert.vue";
import { Status } from "@/composables/useApi";
import useScrollToBottom from "@/composables/useScrollToBottom";
import useChat from "@/composables/useChat";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "Chat",
  components: {
    ChatEmpty,
    ChatMessagesList,
    ChatMessageBox,
    Alert,
  },
  setup() {
    const {
      messages,
      page,
      errorMessage,
      onError,
      onSendMessage,
      nextPage,
      response,
      status,
      fetchMessages,
      fetchLastMessages,
      reset,
    } = useChat();
    const breakpoints = useBreakpoints();
    const height = ref<number>(400);

    reset();

    const selector = breakpoints.isMobile
      ? ".account-layout__content"
      : ".chat";

    watch(
      () => status.value,
      (value) => {
        if (value === Status.SUCCESS && page.value === 1) {
          setTimeout(async () => {
            await nextTick();
            useScrollToBottom(selector);
          }, 100);
        }
      },
      {
        immediate: true,
      }
    );

    onMounted(() => {
      const div = document.querySelector(
        selector
      ) as HTMLElement as HTMLElement;

      div.addEventListener("scroll", () => {
        if (div.scrollTop === 0 && response.value) {
          const { is_next_page_available } = response.value;
          if (is_next_page_available) {
            nextPage();
          }
        }
      });
    });

    onMounted(() => {
      height.value = window.innerHeight - (146 + 31 + 55 + 30 + 50) - 110;
    });

    onBeforeRouteLeave(() => {
      reset();
    });

    watch(
      () => page.value,
      async () => {
        await fetchMessages();
      },
      {
        immediate: true,
      }
    );

    const timerInterval = setInterval(async () => {
      await fetchLastMessages();
    }, 10000);

    onUnmounted(() => {
      clearInterval(timerInterval);
    });

    return {
      messages,
      onSendMessage,
      onError,
      errorMessage,
      loading: computed(() => status.value === Status.LOADING),
      breakpoints,
      height,
    };
  },
});
