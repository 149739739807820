import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_card = _resolveComponent("profile-card")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_account_activation_modal = _resolveComponent("account-activation-modal")!
  const _component_resolving_limitation_modal = _resolveComponent("resolving-limitation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_profile_card),
    (_ctx.user?.item.status === 'unverified')
      ? (_openBlock(), _createBlock(_component_form_button, {
          key: 0,
          "full-width": "",
          type: "button",
          label: "فعالسازی حساب",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activationForm = true)),
          class: "mb-4"
        }))
      : (
      _ctx.user?.item.verification_bottom === 'show' ||
      _ctx.user?.item.verification_bottom === 'show_and_inactive'
    )
        ? (_openBlock(), _createBlock(_component_form_button, {
            key: 1,
            disabled: _ctx.isResolvingLimitationButtonDisabled,
            "bordered-disabled": "",
            "full-width": "",
            label: 
      _ctx.isResolvingLimitationButtonDisabled
        ? 'رفع محدودیت خرید (درحال بررسی)'
        : 'رفع محدودیت خرید'
    ,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resolvingLimitationForm = true)),
            class: "mb-4"
          }, null, 8, ["disabled", "label"]))
        : _createCommentVNode("", true),
    _createVNode(_component_account_activation_modal, {
      modelValue: _ctx.activationForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activationForm) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_resolving_limitation_modal, {
      modelValue: _ctx.resolvingLimitationForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.resolvingLimitationForm) = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}