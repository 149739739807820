
import { defineComponent, PropType, ref } from "vue";
import SlideUpDown from "vue3-slide-up-down";
import Alert from "@/components/Ui/Alert.vue";
import { Transaction } from "@/types/transaction";
import { copyToClipboard } from "@/utils";

export default defineComponent({
  name: "MobileTransactionCard",
  components: {
    SlideUpDown,
    Alert,
  },
  props: {
    type: {
      required: true,
      type: String as PropType<"buy" | "sell">,
    },
    transaction: {
      required: true,
      type: Object as PropType<Transaction>,
    },
  },
  setup() {
    const isOpen = ref<boolean>(false);
    const copied = ref<boolean>(false);

    const copy = (txt: string) => {
      copyToClipboard(txt);
      copied.value = true;
    };

    return {
      isOpen,
      copied,
      copy,
    };
  },
});
