
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "BankCard",
  props: {
    isDefault: Boolean,
    cardNumber: {
      type: String,
      required: false,
      default: null,
      validator: (value: string) => (value ? value.length === 16 : true),
    },
    sheba: {
      type: String,
      required: false,
      default: "",
      validator: (value: string) => (value ? value.length === 26 : true),
    },
    author: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    logo: {
      required: true,
      type: String,
    },
    bank: {
      required: true,
      type: String,
    },
  },
  emits: ["set-default", "remove"],
  setup(props) {
    const number = computed(() => {
      if (props.cardNumber) {
        return props.cardNumber.match(/.{1,4}/g);
      } else {
        return null;
      }
    });

    return {
      number,
    };
  },
});
