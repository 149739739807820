import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-511af0ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-layout" }
const _hoisted_2 = { class: "account-layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_header = _resolveComponent("top-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom_menu = _resolveComponent("bottom-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_top_header),
      _createElementVNode("div", {
        class: "account-layout__body",
        style: _normalizeStyle({ marginTop: _ctx.marginTop })
      }, [
        _createVNode(_component_router_view)
      ], 4)
    ]),
    (_ctx.user)
      ? (_openBlock(), _createBlock(_component_bottom_menu, {
          key: 0,
          "unread-count": _ctx.unreadCount
        }, null, 8, ["unread-count"]))
      : _createCommentVNode("", true)
  ]))
}