
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import Swiper, { Pagination, Navigation, Manipulation } from "swiper";
import NewCard from "./NewCard.vue";
import BankCard from "./BankCard.vue";
import Modal from "@/components/Ui/Modal.vue";
import useAccount from "@/composables/useAccount";

Swiper.use([Pagination, Navigation, Manipulation]);

export default defineComponent({
  name: "CardsCarousel",
  components: {
    NewCard,
    BankCard,
    Modal,
  },
  emits: ["new"],
  setup() {
    const modal = ref<boolean>(false);
    const selectedAccount = ref<[number, number]>([0, 0]);
    const { items, setDefault, remove } = useAccount();
    let swiper: Swiper;

    const setAccountDefault = async (id: number) => setDefault(id);
    const removeAccount = async () => {
      await remove(selectedAccount.value[0]);
      swiper.removeSlide(selectedAccount.value[1]);
      modal.value = false;
    };
    let timer: number;

    onMounted(() => {
      swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        spaceBetween: 20,
        loop: false,
        slidesPerView: "auto",
        centeredSlides: true,
        height: 215,
      });

      timer = setTimeout(() => {
        if (items.value.length) {
          const defaultAccount = items.value.findIndex(
            (item) => item.is_default
          );
          if (defaultAccount !== -1) {
            swiper.slideTo(defaultAccount + 1);
          } else {
            swiper.slideTo(1);
          }
        }
      }, 300);
    });

    onUnmounted(() => clearTimeout(timer));

    return {
      modal,
      items,
      setAccountDefault,
      removeAccount,
      selectedAccount,
    };
  },
});
