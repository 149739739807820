
import { defineComponent, ref, watch } from "vue";
import ProfileCard from "@/components/Page/Account/Profile/ProfileCard.vue";
import AccountActivationModal from "@/components/Shared/AccountActivationModal.vue";
import ResolvingLimitationModal from "@/components/Shared/ResolvingLimitationModal.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "ProfilePage",
  components: {
    ProfileCard,
    AccountActivationModal,
    ResolvingLimitationModal,
    FormButton,
  },
  setup() {
    useMetaTitle("پروفایل");

    const activationForm = ref<boolean>(false);
    const resolvingLimitationForm = ref<boolean>(false);
    const help = ref<boolean>(false);
    const isResolvingLimitationButtonDisabled = ref<boolean>(false);

    const { user, fetchUserDetails } = useAuth();

    watch(
      () => user.value,
      (value) => {
        if (value) {
          isResolvingLimitationButtonDisabled.value =
            value.item.verification_bottom === "show_and_inactive";
        }
      },
      {
        immediate: true,
      }
    );

    return {
      activationForm,
      user,
      fetchUserDetails,
      resolvingLimitationForm,
      help,
      isResolvingLimitationButtonDisabled,
    };
  },
});
