
import {
  defineComponent,
  onUnmounted,
  watch,
  defineAsyncComponent,
  onMounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import TetherResult from "@/components/Page/Account/Order/Sell/Status/TetherResult.vue";
import PaymentInfo from "@/components/Page/Account/Order/Sell/Status/PaymentInfo.vue";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useSellOrder from "@/composables/useSellOrder";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "SellStatusPage",
  components: {
    TetherResult,
    PaymentInfo,
    Windows10Loading,
    PreOrderPriceBox: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderPriceBox.vue")
    ),
    PreOrderSteps: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderSteps.vue")
    ),
  },
  setup() {
    useMetaTitle("فروش تتر به ما");
    const router = useRouter();
    const route = useRoute();
    let timer: number;
    const breakpoints = useBreakpoints();

    const { result, sellInvoice, sellInvoiceStatus } = useSellOrder();

    const fetchSellInvoice = async () => {
      await sellInvoice({
        trackid: route.params.id as string,
      });
    };

    if (!result.value) {
      fetchSellInvoice().then(() => {
        if (result.value?.status === "sending") {
          router.push({
            name: "SellInvoice",
            params: {
              id: result.value?.public_id,
            },
          });
        }
      });
    }

    timer = setInterval(() => {
      fetchSellInvoice();
    }, 30000);

    watch(
      () => result.value,
      (value) => {
        if (value?.progress_percent === 100) {
          setTimeout(() => {
            router.push({
              name: "SellInvoice",
              params: { id: value.public_id },
            });
          }, 1000);
        }
      }
    );

    onMounted(() => {
      window.onpopstate = () => {
        if (confirm("آیا مطمئن هستید؟")) {
          router.push({
            name: "Order",
            query: {
              type: "sell",
            },
          });
        } else {
          router.push({
            name: "SellStatus",
            params: {
              id: route.params.id,
            },
          });
        }
      };
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {
      result,
      status: sellInvoiceStatus,
      breakpoints,
    };
  },
});
