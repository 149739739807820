
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import TopHeader from "@/components/Layout/Account/TopHeader.vue";
import BottomMenu from "@/components/Layout/Account/BottomMenu.vue";
import useAuth from "@/composables/useAuth";
import useUnreadMessages from "@/composables/useUnreadMessages";

export default defineComponent({
  name: "AccountLayout",
  components: {
    TopHeader,
    BottomMenu,
  },
  setup() {
    const route = useRoute();
    const { user } = useAuth();
    const { unreadCount } = useUnreadMessages();

    const marginTop = computed(() => {
      if (route.name === "Profile") {
        return "-130px";
      } else if (route.name === "Order") {
        return "-90px";
      } else if (route.name === "Account") {
        return "-50px";
      }
      return "";
    });

    return {
      unreadCount,
      marginTop,
      user,
    };
  },
});
