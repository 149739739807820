
import { defineComponent, PropType } from "vue";
import ChatMessage from "./ChatMessage.vue";
import { Chat } from "@/types/chat";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "ChatMessagesList",
  props: {
    messages: {
      type: Array as PropType<Chat[]>,
      required: true,
    },
    loading: Boolean,
  },
  components: {
    ChatMessage,
  },
  setup() {
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
