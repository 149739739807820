import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-4" }
const _hoisted_3 = { class: "col-12 col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tether_calculator = _resolveComponent("tether-calculator")!
  const _component_video_help_link = _resolveComponent("video-help-link")!
  const _component_tether_chart = _resolveComponent("tether-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tether_calculator, {
        flat: _ctx.breakpoints.isDesktop,
        activeTab: _ctx.$route.query?.tab
      }, null, 8, ["flat", "activeTab"]),
      (_ctx.breakpoints.isMobile)
        ? (_openBlock(), _createBlock(_component_video_help_link, {
            key: 0,
            title: "آموزش ویدئویی خرید",
            url: "#",
            class: "mt-3"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.breakpoints.isDesktop)
        ? (_openBlock(), _createBlock(_component_tether_chart, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}