
import { defineComponent, ref, defineAsyncComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import BuyForm from "@/components/Page/Account/Order/Buy/BuyForm.vue";
import BuyAttention from "@/components/Page/Account/Order/Buy/BuyAttention.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBuyOrder from "@/composables/useBuyOrder";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "BuyPage",
  components: {
    TetherCalculator: defineAsyncComponent(
      () => import("@/components/Shared/TetherCalculator/TetherCalculator.vue")
    ),
    BuyForm,
    BuyAttention,
    Windows10Loading,
  },
  setup() {
    useMetaTitle("سفارش خرید");
    const route = useRoute();
    const router = useRouter();
    const submited = ref<boolean>(false);
    const { result, buyInvoice, buyInvoiceStatus } = useBuyOrder();
    const breakpoints = useBreakpoints();

    if (!result.value) {
      buyInvoice({ trackid: route.params.id as string });
    }

    const onSubmit = () =>
      router.push({ name: "BuyStatus", params: { id: route.params.id } });

    return {
      submited,
      status: buyInvoiceStatus,
      onSubmit,
      breakpoints,
      result,
    };
  },
});
