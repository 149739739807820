
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "vue";
import { AccountItem } from "@/types/order";
import useBreakpoints from "@/composables/useBreakpoints";
import useIsIOS from "@/composables/useIsIOS";

export default defineComponent({
  name: "FormAccountSelector",
  props: {
    modelValue: {
      required: false,
      type: [String, Number],
      default: null,
    },
    label: {
      required: false,
      default: "حساب ها",
    },
    error: Boolean,
    autofocus: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    accounts: {
      required: true,
      type: Array as PropType<AccountItem[]>,
    },
    type: {
      required: true,
      type: String as PropType<"sheba" | "card">,
      validator: (value: string) => value === "sheba" || value === "card",
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const active = ref<boolean>(props.disabled ? false : props.autofocus);
    const { isMobile } = useBreakpoints();
    const isIOS = useIsIOS();

    if (props.readonly) {
      active.value = false;
    }

    const currentAccount = computed(() => {
      const account = props.accounts.find(
        (account) =>
          account.ir_num === props.modelValue ||
          account.card_num === props.modelValue
      );

      return account;
    });

    const formatedSheba = (sheba: string) => {
      return isMobile ? sheba.slice(0, 5) + "***" + sheba.slice(-7) : sheba;
    };
    const formatedCard = (card: string) => {
      return isMobile ? card.slice(0, 6) + "***" + card.slice(-4) : card;
    };

    return {
      active,
      id: `input-${instance?.uid}`,
      currentAccount,
      formatedSheba,
      formatedCard,
      isIOS,
    };
  },
});
