
import { defineComponent, ref } from "vue";
import CardsCarousel from "./CardsCarousel.vue";
import CardsCarouselLoading from "./CardsCarouselLoading.vue";
import CardsList from "./CardsList.vue";
import Alert from "@/components/Ui/Alert.vue";
import Modal from "@/components/Ui/Modal.vue";
import BankAccountRegistrationForm from "@/components/Shared/BankAccountRegistrationForm.vue";
import useAccount from "@/composables/useAccount";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "Accounts",
  components: {
    CardsCarousel,
    CardsCarouselLoading,
    Alert,
    Modal,
    BankAccountRegistrationForm,
    CardsList,
  },
  setup() {
    const modal = ref<boolean>(false);
    const accountRegistrationFormSuccess = ref<boolean>(false);
    const { fetch, loading } = useAccount();
    const breakpoints = useBreakpoints();

    fetch();

    const onSubmit = async () => {
      accountRegistrationFormSuccess.value = true;
      modal.value = false;

      await fetch();
    };

    return {
      breakpoints,
      modal,
      accountRegistrationFormSuccess,
      loading,
      onSubmit,
    };
  },
});
