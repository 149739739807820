
import { defineComponent } from "vue";
import Chat from "@/components/Page/Account/Support/Chat.vue";
import useMetaTitle from "@/composables/useMetaTitle";

export default defineComponent({
  name: "SupportPage",
  components: {
    Chat,
  },
  setup() {
    useMetaTitle("پشتیبانی");
  },
});
