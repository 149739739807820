
import { computed, defineAsyncComponent, defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useSellOrder from "@/composables/useSellOrder";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "SellInvoicePage",
  components: {
    MobileInvoiceSuccess: defineAsyncComponent(
      () => import("@/components/Shared/MobileInvoiceSuccess.vue")
    ),
    DesktopInvoiceSuccess: defineAsyncComponent(
      () => import("@/components/Shared/DesktopInvoiceSuccess.vue")
    ),
    PreOrderPriceBox: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderPriceBox.vue")
    ),
    PreOrderSteps: defineAsyncComponent(
      () => import("@/components/Shared/PreOrderSteps.vue")
    ),
    Windows10Loading,
  },
  setup() {
    useMetaTitle("فاکتور فروش");
    const route = useRoute();
    const router = useRouter();
    const { invoice, invoiceResponse, invoiceError, invoiceStatus } =
      useSellOrder();
    const breakpoints = useBreakpoints();

    invoice({ public_id: route.params.id as string });

    watch(
      () => invoiceError.value,
      (value) => {
        if (value) {
          router.push({ name: "NotFound" });
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      () => invoiceResponse.value,
      (value) => {
        if (
          value &&
          value.result.status !== "completed" &&
          value.result.status !== "sending"
        ) {
          router.push({ name: "Order" });
        }
      }
    );

    return {
      response: computed(() => invoiceResponse.value?.result),
      status: invoiceStatus,
      breakpoints,
    };
  },
});
