import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_transactions_list = _resolveComponent("mobile-transactions-list")!
  const _component_desktop_transactions_list = _resolveComponent("desktop-transactions-list")!
  const _component_transactions_empty = _resolveComponent("transactions-empty")!

  return (_ctx.items.length)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.breakpoints.isMobile)
          ? (_openBlock(), _createBlock(_component_mobile_transactions_list, {
              key: 0,
              transactions: _ctx.items,
              loading: _ctx.loading,
              onNext: _ctx.nextPage
            }, null, 8, ["transactions", "loading", "onNext"]))
          : (_ctx.breakpoints.isDesktop)
            ? (_openBlock(), _createBlock(_component_desktop_transactions_list, {
                key: 1,
                transactions: _ctx.items,
                loading: _ctx.loading,
                onNext: _ctx.nextPage
              }, null, 8, ["transactions", "loading", "onNext"]))
            : _createCommentVNode("", true)
      ], 64))
    : (_openBlock(), _createBlock(_component_transactions_empty, { key: 1 }))
}